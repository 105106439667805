.footer_paren_div {
  margin-top: 100px;
  background-color: rgb(20, 20, 20);
  color: white;
  padding: 30px 50px;
}
.footer_parent_grid_div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* align-items: center; */
  text-align: left;
  width: 70%;
  margin: auto;
  gap: 100px;
  margin-bottom: 50px;
}

.footer_lorem {
  margin-top: 20px;
  margin-bottom: 20px;
  color: gray;
}
.footer_icon_flex_div {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  font-size: 14px;
}
.our_recent_posts {
  font-size: 20px;
  margin-bottom: 32px;
}
.footer_navigation_menu {
  line-height: 30px;
}
.footer_subscribe_to {
  font-size: 22px;
  margin-bottom: 32px;
}
.footer_input_name {
  margin-top: 20px;
  padding: 10px;
  width: 100%;
}
.footer_input_email {
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  margin-top: 5px;
}
.footer_subscribe_btn {
  width: 100%;
  background-color: aqua;
  padding: 10px;
  margin-top: 5px;
}
.ourInstagram {
  font-size: 22px;
  margin-bottom: 32px;
}
.Aliquam_lorem {
  color: gray;
}
.social_media {
  font-size: 20px;
}

.social_media_instagram {
  font-size: 22px;
  margin-left: 20px;
}
@media screen and (max-width: 1250px) {
  .footer_parent_grid_div {
    grid-template-columns: repeat(3, 1fr);
  }
  .musafir_title {
    font-size: 25px;
  }
}
@media screen and (max-width: 1200px) {
  .footer_parent_grid_div {
    grid-template-columns: repeat(2, 1fr);
    width: 70%;
  }
}
@media screen and (max-width: 950px) {
  .footer_parent_grid_div {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 800px) {
  .footer_parent_grid_div {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
  .musafirAirTravelLogoTwo {
    width: 50%;
  }
}
@media screen and (max-width: 650px) {
  .footer_parent_grid_div {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 400px) {
  .musafirAirTravelLogoTwo {
    width: 80%;
  }
}
