.Municipalities_parent_div {
  text-align: left;
  margin-top: 100px;
  font-size: 14px;
}
.Municipalities_heading {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}
.Municipalities_photo_flex_div {
  display: flex;
  gap: 20px;
  margin-top: 40px;
}
.MunicipalitiesPhotoOne {
  width: 70%;
  height: 50%;
}

.MunicipalitiesPhotoTwo {
  width: 50%;
  height: 310px;
}

.municipalitiesPhoto_grid_div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;

  width: 107%;
}
.municipalitiesPhoto_grid_div img {
  width: 100%;
}

@media screen and (max-width: 1600px) {
  .Municipalities_photo_flex_div {
    width: 90%;
  }
  .MunicipalitiesPhotoOne {
    width: 75%;
    height: 305px;
  }

  .MunicipalitiesPhotoTwo {
    width: 35%;
    height: 310px;
  }
  .municipalitiesPhoto_grid_div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;

    width: 102%;
  }
}
@media screen and (max-width: 1250px) {
  .municipalitiesPhoto_grid_div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;

    width: 102%;
  }
}
@media screen and (max-width: 1200px) {
  .municipalitiesPhoto_grid_div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;

    width: 102%;
  }
}
@media screen and (max-width: 950px) {
  .municipalitiesPhoto_grid_div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 20px;

    width: 102%;
  }
}
