.home_flex_div {
  background-color: white;
  display: flex;
  justify-content: center;
  width: 75%;
  gap: 50px;
  margin: auto;
  margin-top: -100px;
  padding: 30px 50px;
  /* box-shadow: 1px 1px 10px 1px rgb(230, 230, 230); */
  box-shadow: 10px 10px 20px 0px rgba(206, 206, 206, 0.5);
}

@media screen and (max-width: 1250px) {
  .home_flex_div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 850px) {
  .home_flex_div {
    margin-top: -50px;
  }
}
@media screen and (max-width: 400px) {
  .home_flex_div {
    width: 100%;
    padding: 50px;
  }
}
