.hotels_parent_div {
  background: url("../../accets/hotelsPhotos/HotelsHeroPhoto.png");
  background-color: rgba(0, 0, 0, 0.404);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
  height: 65vh;
  width: 100%;
  /* background-attachment: fixed; */
}

.hotels_parent_div h1 {
  color: white;
  position: absolute;
  margin-left: 830px;
  margin-top: 200px;
  font-size: 30px;
  font-weight: 600;
}
.welcome_to_musafir {
  width: 76%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
}
.hotels_text_img_grid_div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 75%;
  gap: 50px;
  margin: auto;
}
.hotels_text_div {
  text-align: left;
}

.hotels_text_p {
  margin-bottom: 30px;
  line-height: 30px;
}
.at_musafir_text-div {
  text-align: left;
  width: 75%;
  margin: auto;
  margin-top: 50px;
}
.hotels_text_lst_p {
  line-height: 30px;
  margin-top: 0;
}
.hotels_cart_img {
  height: 250px;
  width: 100%;
}

@media screen and (max-width: 1650px) {
  .hotels_parent_div h1 {
    margin-left: 750px;
  }
}
@media screen and (max-width: 1550px) {
  .hotels_parent_div h1 {
    margin-left: 700px;
  }
}
@media screen and (max-width: 1450px) {
  .hotels_parent_div h1 {
    margin-left: 600px;
  }
}
@media screen and (max-width: 1400px) {
  .hotels_text_img_grid_div {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 1250px) {
  .hotels_parent_div h1 {
    margin-left: 530px;
  }
}
@media screen and (max-width: 1200px) {
  .tourPackage_greed_cart_div {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 1100px) {
  .hotels_parent_div h1 {
    margin-left: 450px;
  }
}
@media screen and (max-width: 1024px) and (max-height: 1366px) {
  .hotels_parent_div h1 {
    margin-top: 380px;
    font-size: 40px;
  }
}
@media screen and (max-width: 1024px) and (max-height: 600px) {
  .hotels_parent_div h1 {
    margin-top: 150px;
    margin-left: 460px;
    font-size: 40px;
  }
}
@media screen and (max-width: 950px) {
  .hotels_parent_div h1 {
    margin-left: 420px;
  }
}
@media screen and (max-width: 900px) {
  .hotels_parent_div h1 {
    margin-left: 380px;
  }
}
@media screen and (max-width: 800px) {
  .hotels_parent_div h1 {
    margin-left: 330px;
  }
  .tourPackage_greed_cart_div {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 800px) and (max-height: 1280px) {
  .hotels_parent_div h1 {
    margin-left: 290px;
    margin-top: 350px;
    font-size: 80px;
  }
}
@media screen and (max-width: 700px) {
  .hotels_parent_div h1 {
    margin-left: 250px;
  }
}
@media screen and (max-width: 712px) and (max-height: 1138px) {
  .hotels_parent_div h1 {
    margin-left: 280px;
    margin-top: 360px;
    font-size: 50px;
  }
}
@media screen and (max-width: 640px) and (max-height: 360px) {
  .hotels_parent_div h1 {
    margin-left: 280px;
    margin-top: 70px;
    font-size: 40px;
  }
}
@media screen and (max-width: 600px) and (max-height: 1024px) {
  .hotels_parent_div h1 {
    margin-left: 210px;
    margin-top: 300px;
    font-size: 70px;
  }
}
@media screen and (max-width: 550px) {
  .hotels_parent_div h1 {
    margin-left: 200px;
  }
}
@media screen and (max-width: 450px) {
  .hotels_parent_div h1 {
    margin-left: 150px;
  }
}
@media screen and (max-width: 540px) and (max-height: 720px) {
  .hotels_parent_div h1 {
    margin-left: 230px;
    margin-top: 200px;
    font-size: 40px;
  }
}
@media screen and (max-width: 430px) and (max-height: 932px) {
  .hotels_parent_div h1 {
    margin-left: 160px;
    margin-top: 260px;
    font-size: 40px;
  }
}
@media screen and (max-width: 414px) and (max-height: 896px) {
  .hotels_parent_div h1 {
    margin-left: 160px;
    margin-top: 260px;
    font-size: 40px;
  }
}
@media screen and (max-width: 412px) and (max-height: 732px) {
  .hotels_parent_div h1 {
    margin-left: 160px;
    margin-top: 180px;
    font-size: 40px;
  }
}
@media screen and (max-width: 400px) {
  .hotels_parent_div h1 {
    margin-left: 110px;
  }
}
@media screen and (max-width: 400px) and (max-height: 700px) {
  .hotels_parent_div h1 {
    margin-left: 160px;
  }
}
@media screen and (max-width: 390px) and (max-height: 844px) {
  .hotels_parent_div h1 {
    margin-left: 145px;
    margin-top: 260px;
    font-size: 40px;
  }
}
@media screen and (max-width: 384px) and (max-height: 640px) {
  .hotels_parent_div h1 {
    margin-left: 145px;
    margin-top: 180px;
    font-size: 40px;
  }
}
@media screen and (max-width: 375px) and (max-height: 667px) {
  .hotels_parent_div h1 {
    margin-left: 140px;
    margin-top: 180px;
    font-size: 40px;
  }
}
@media screen and (max-width: 360px) and (max-height: 740px) {
  .hotels_parent_div h1 {
    margin-left: 130px;
    margin-top: 180px;
    font-size: 40px;
  }
}
@media screen and (max-width: 360px) and (max-height: 640px) {
  .hotels_parent_div h1 {
    margin-left: 130px;
    margin-top: 180px;
    font-size: 40px;
  }
}
@media screen and (max-width: 350px) {
  .hotels_parent_div h1 {
    margin-left: 100px;
    font-size: 20px;
  }
}

@media screen and (max-width: 320px) and (max-height: 658px) {
  .hotels_parent_div h1 {
    margin-left: 120px;
    margin-top: 180px;
    font-size: 30px;
  }
}
@media screen and (max-width: 300px) {
  .hotels_parent_div h1 {
    margin-left: 60px;
    font-size: 20px;
  }
}
@media screen and (max-width: 280px) and (max-height: 653px) {
  .hotels_parent_div h1 {
    margin-left: 110px;
    font-size: 30px;
  }
}
@media screen and (max-width: 250px) {
  .hotels_parent_div h1 {
    margin-left: 50px;
    font-size: 17px;
  }
}
