.hero_section_parent_div {
  background: rgba(0, 0, 0, 0.2)
    /* url("../../accets/visaService/visaServiceHero.png"); */
    url("../../accets/heorPhoto.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
  height: 80vh;

  width: 100%;
  background-attachment: fixed;
}
.hero_text_div {
  position: absolute;
  margin-left: 250px;
  margin-top: 150px;
}
.destination {
  font-family: Satisfy, cursive;
  font-size: 20px;
  color: white;
}
.italy {
  font-size: 50px;
  color: white;
  font-weight: 500;
  margin-top: 100px;
}

@media screen and (max-width: 1650px) {
  .hero_text_div {
    margin-left: 220px;
  }
}
@media screen and (max-width: 1600px) {
  .hero_text_div {
    margin-left: 200px;
  }
}
@media screen and (max-width: 1570px) {
  .hero_text_div {
    margin-left: 187px;
  }
}
@media screen and (max-width: 1550px) {
  .hero_text_div {
    margin-left: 175px;
  }
}
@media screen and (max-width: 1450px) {
  .hero_text_div {
    margin-left: 130px;
  }
}
@media screen and (max-width: 1400px) {
  .hero_text_div {
    margin-left: 110px;
  }
}
@media screen and (max-width: 1350px) {
  .hero_text_div {
    margin-left: 70px;
  }
}
@media screen and (max-width: 1300px) {
  .hero_text_div {
    margin-left: 40px;
  }
}
@media screen and (max-width: 1240px) {
  .hero_text_div {
    margin-left: 15px;
  }
}
@media screen and (max-width: 1215px) {
  .hero_text_div {
    margin-left: 130px;
    margin-top: 90px;
  }
  .hero_text_div h1 {
    font-size: 40px;
  }
}
@media screen and (max-width: 1200px) {
  .hero_text_div {
    /* margin-left: 0px; */
  }
}
@media screen and (max-width: 1150px) {
  .hero_text_div {
    margin-left: 100px;
  }
}
@media screen and (max-width: 1100px) {
  .hero_text_div {
    margin-left: 60px;
  }
}
@media screen and (max-width: 1080px) {
  .hero_text_div {
    /* margin-left: 0px; */
  }
}
@media screen and (max-width: 1050px) {
  .hero_text_div {
    /* margin-left: 0px; */
  }
}
@media screen and (max-width: 1028px) {
  .hero_text_div {
    margin-left: 75px;
  }
  .hero_text_div h1 {
    font-size: 37px;
  }
}

@media screen and (max-width: 1024px) and (max-height: 1366px) {
  .hero_section_parent_div {
    height: 35vh;
  }
}
@media screen and (max-width: 1024px) and (max-height: 707px) {
  .hero_section_parent_div {
    height: 50vh;
  }
  .hero_text_div {
    margin-top: 50px;
  }
}
@media screen and (max-width: 1024px) and (max-height: 600px) {
  .hero_section_parent_div {
    height: 50vh;
  }
  .hero_text_div {
    margin-top: 30px;
  }
}
@media screen and (max-width: 1000px) {
  .hero_text_div {
    /* margin-left: 0px; */
  }
}
@media screen and (max-width: 963px) {
  .hero_text_div {
    margin-left: 30px;
  }
}
@media screen and (max-width: 919px) {
  .hero_text_div {
    margin-left: 15px;
    margin-top: 0px;
  }
}
@media screen and (max-width: 918px) {
  .hero_text_div {
    margin-left: 15px;
  }
}
@media screen and (max-width: 912px) {
  .hero_text_div {
    margin-left: 15px;
    margin-top: -50px;
  }
}
@media screen and (max-width: 912px) and (max-height: 1368px) {
  .hero_section_parent_div {
    height: 40vh;
  }
}
@media screen and (max-width: 903px) {
  .hero_text_div {
    margin-left: 50px;
    margin-top: -40px;
  }
  .hero_text_div h1 {
    font-size: 34px;
  }
}
@media screen and (max-width: 870px) {
  .hero_text_div {
    margin-left: 30px;
  }
}

@media screen and (max-width: 853px) and (max-height: 1280px) {
  .hero_text_div {
    margin-left: 145px;
  }
  .italy {
    font-size: 40px;
  }
}
@media screen and (max-width: 853px) and (max-height: 707px) {
  .hero_text_div {
    margin-left: 0px;
  }
  .italy {
    font-size: 40px;
  }
}

@media screen and (max-width: 853px) {
  .hero_text_div {
    margin-left: 20px;
  }
}
@media screen and (max-width: 850px) {
  .hero_text_div {
    margin-left: 15px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 831px) {
  .hero_text_div {
    margin-left: 30px;
    margin-top: 20px;
  }
  .hero_text_div h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 830px) and (max-height: 707px) {
  .hero_text_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 830px) and (max-height: 647px) {
  .hero_text_div {
    margin-left: 40px;
  }
}
@media screen and (max-width: 800px) and (max-height: 1280px) {
  .hero_text_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 800px) and (max-height: 707px) {
  .hero_text_div {
    margin-left: 20px;
  }
}
@media screen and (max-width: 750px) {
  .hero_text_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 700px) {
  .hero_text_div {
    margin-left: 0px;
  }
  .italy {
    font-size: 35px;
  }
}
@media screen and (max-width: 650px) {
  .hero_text_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 640px) and (max-height: 360px) {
  .hero_section_parent_div {
    height: 70vh;
  }
  .hero_text_div {
    margin-left: 0px;
    margin-top: 20px;
    width: 100%;
  }
}
@media screen and (max-width: 620px) {
  .hero_text_div {
    margin-left: 0px;
  }
  .italy {
    font-size: 30px;
  }
}
@media screen and (max-width: 589px) {
  .hero_text_div {
    margin-top: 0;
  }
}
@media screen and (max-width: 570px) {
  .hero_text_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 550px) {
  .hero_text_div {
    margin-left: 0px;
  }
}

@media screen and (max-width: 540px) and (max-height: 720px) {
  .hero_text_div {
    margin-top: 50px;
  }
}
@media screen and (max-width: 540px) {
  .hero_text_div {
    margin-top: 0;
  }
}
@media screen and (max-width: 530px) {
  .hero_text_div {
    margin-left: 0px;
  }
  .italy {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .hero_text_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 450px) {
  .hero_text_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 430px) {
  .hero_text_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 414px) and (max-height: 736px) {
  .hero_text_div {
    margin-top: 50px;
  }
}
@media screen and (max-width: 414px) {
  .hero_text_div {
    margin-top: 0px;
  }
}
@media screen and (max-width: 411px) and (max-height: 823px) {
  .hero_text_div {
    margin-top: 50px;
  }
}
@media screen and (max-width: 411px) and (max-height: 731px) {
  .hero_text_div {
    margin-top: 50px;
  }
}
@media screen and (max-width: 411px) {
  .hero_text_div {
    margin-top: 0px;
  }
}
@media screen and (max-width: 404px) {
  .hero_text_div h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 400px) and (max-height: 700px) {
  .hero_text_div {
    margin-left: 0px;
    margin-top: 40px;
  }
}
@media screen and (max-width: 400px) {
  .hero_text_div {
    margin-top: 0px;
  }
}
@media screen and (max-width: 393px) and (max-height: 786px) {
  .hero_text_div {
    margin-left: 0px;
    margin-top: 75px;
  }
}
@media screen and (max-width: 393px) {
  .hero_text_div {
    margin-top: 0px;
  }
}
@media screen and (max-width: 390px) and (max-height: 844px) {
  .hero_text_div {
    margin-left: 0px;
    margin-top: 75px;
  }
}
@media screen and (max-width: 390px) {
  .hero_text_div {
    margin-top: 0px;
  }
}
@media screen and (max-width: 384px) and (max-height: 640px) {
  .hero_text_div {
    margin-left: 0px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 375px) and (max-height: 667px) {
  .hero_text_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 370px) {
  .hero_text_div {
    margin-top: 20px;
  }
}
@media screen and (max-width: 360px) {
  .hero_text_div h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 360px) and (max-height: 740px) {
  .hero_text_div {
    margin-left: 0px;
  }
}

@media screen and (max-width: 360px) and (max-height: 640px) {
  .hero_text_div {
    margin-left: 0px;
    margin-top: 50px;
  }
}
@media screen and (max-width: 360px) and (max-height: 707px) {
  .hero_text_div {
    margin-left: 26px;
  }
}
@media screen and (max-width: 330px) {
  .hero_text_div {
    margin-left: 15px;
  }
}
@media screen and (max-width: 320px) and (max-height: 658px) {
  .hero_text_div {
    margin-left: 10px;
  }
}
@media screen and (max-width: 320px) and (max-height: 480px) {
  .hero_text_div {
    margin-top: 15px;
  }
}
@media screen and (max-width: 309px) {
  .hero_text_div {
    margin-left: 5px;
  }
}
@media screen and (max-width: 300px) {
  .hero_text_div {
    margin-left: 18px;
  }
  .destination {
    font-size: 30px;
  }
  .italy {
    font-size: 22px;
  }
}
@media screen and (max-width: 280px) {
  .destination {
    font-size: 25px;
  }
  .italy {
    font-size: 20px;
  }
}
@media screen and (max-width: 250px) {
  .hero_text_div {
    margin-left: 50px;
  }
}
@media screen and (max-width: 200px) {
  .hero_text_div {
    margin-left: 40px;
  }
}
